import React, { Component } from "react";
import firebase from "./firebase";
import BG from "./bg.jpg";
import styled from "styled-components";
import ReactPlayer from "react-player";
import video from "./Zim-Welcome-1.mp4";

const Container = styled.div`
  background-image: url(${props => props.bg});
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .topBox {
    height: 272px;
    h2 {
      color: #037abe;
      font-size: ${props => props.messageFontSize};
      text-transform: uppercase;
      margin: 0px;
      position: absolute;
      top: 40px;
      left: 100px;
    }
  }
  .bottomBox {
    height: 595px;
    display: grid;
    justify-content: center;
    align-content: center;
    h1 {
      text-align: center;
      color: #fff;
      font-size: 130px;
      margin: 0px;
    }
  }
  .companyBox {
    height: 213px;
    position: relative;
    h3 {
      position: absolute;
      color: #fff;
      font-size: 74px;
      font-weight: 500;
      margin: 0px;
      left: 900px;
      top: 20px;
    }
  }
`;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      line1: "",
      line2: "",
      line3: "",
      nameCompany: "",
      message: "",
      video: false,
      messageFontSize: "160px"
    };
  }

  componentWillMount() {
    var db = firebase.firestore();
    db.collection("welcome")
      .doc("details")
      .onSnapshot(
        function(doc) {
          this.setState({
            line1: doc.data().line1,
            line2: doc.data().line2,
            line3: doc.data().line3,
            nameCompany: doc.data().nameCompany,
            message: doc.data().message,
            video: doc.data().video
          });
        }.bind(this)
      );
  }

  render() {
    // console.log(this.state.message.length)
    // console.log(this.state.messageFontSize)

    return (
      <>
        {this.state.video && (
          <ReactPlayer
            url={video}
            loop
            muted
            playing
            width='1920px'
            height='1080px'
          />
        )}
        {!this.state.video &&
          this.state.line1 &&
          this.state.line2 &&
          this.state.line3 && (
            <Container bg={BG} messageFontSize={this.state.messageFontSize}>
              <div className='topBox'>
                <h2>{this.state.message}</h2>
              </div>
              <div className='bottomBox'>
                <h1>{this.state.line1}</h1>
                <h1>{this.state.line2}</h1>
                <h1>{this.state.line3}</h1>
              </div>
              <div className='companyBox'>
                <h3>{this.state.nameCompany}</h3>
              </div>
            </Container>
          )}
        {!this.state.video &&
          this.state.line1 &&
          this.state.line2 &&
          !this.state.line3 && (
            <Container bg={BG} messageFontSize={this.state.messageFontSize}>
              <div className='topBox'>
                <h2>{this.state.message}</h2>
              </div>
              <div className='bottomBox'>
                <h1>{this.state.line1}</h1>
                <h1>{this.state.line2}</h1>
              </div>
              <div className='companyBox'>
                <h3>{this.state.nameCompany}</h3>
              </div>
            </Container>
          )}
        {!this.state.video &&
          this.state.line1 &&
          !this.state.line2 &&
          !this.state.line3 && (
            <Container bg={BG} messageFontSize={this.state.messageFontSize}>
              <div className='topBox'>
                <h2>{this.state.message}</h2>
              </div>
              <div className='bottomBox'>
                <h1>{this.state.line1}</h1>
              </div>
              <div className='companyBox'>
                <h3>{this.state.nameCompany}</h3>
              </div>
            </Container>
          )}
        {!this.state.video &&
          !this.state.line1 &&
          !this.state.line1 &&
          !this.state.line3 && (
            <Container bg={BG} messageFontSize={this.state.messageFontSize}>
              <div className='topBox'>
                <h2>{this.state.message}</h2>
              </div>
              <div className='bottomBox'>
                <h1>{this.state.nameCompany}</h1>
              </div>
              <div className='companyBox'></div>
            </Container>
          )}
      </>
    );
  }
}

export default App;
