import firebase from 'firebase';

// initialise database

const config = {
    apiKey: "AIzaSyCWxGGDuSWr0nEJmimLyLINutPL3wN9Y6U",
    authDomain: "zimmer-biomet-welcome.firebaseapp.com",
    databaseURL: "https://zimmer-biomet-welcome.firebaseio.com",
    projectId: "zimmer-biomet-welcome",
    storageBucket: "",
    messagingSenderId: "808400300248",
    appId: "1:808400300248:web:755eccf34a53fb85"
}

firebase.initializeApp(config);
export default firebase;