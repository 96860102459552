import React, { Component } from "react";
import firebase from "./firebase";
import BG from "./bg.jpg";
import bgArea from "./bg-area.jpeg";
import styled from "styled-components";
import ReactPlayer from "react-player";
import video from "./Zim-Welcome-sml_2.mp4";
import Logo from "./logo.png";

const Container = styled.div`
  background-color: #eee;
  height: 100vh;
  overflow-y: scroll;
 /* background-color: #037ABE; */
  h1 {
    text-align: center;
    color: #fff;
    font-size: 100px;
    margin: 30px 0px;
  }
  label {
      display: block;
      margin-bottom: 10px;
  }
  input {
    width: 500px;
    height: 30px;
    margin-bottom: 20px;
    font-size: 20px;
    border: 1px solid #bbb;
    padding: 5px 8px;
  }
  button {
    padding: 15px 30px;
    font-size: 16px;
    background-color: #333;
    color: #fff;
    border: none;
    :hover {
        background-color: #0081CD;
    }
  }
.formBox {
  p {
    max-width: 500px;
    margin-bottom: 30px;
  }
}

  .topSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    background-color: #fff;
    padding: 30px 60px;
    img {
      width: 300px;
    }
    h2 {
      color: #333;
      font-size: 30px;
      text-transform: uppercase;
      margin: 0px;
      text-align: right;
    }
  }


  .bottomSection {
    /* background-image: url(${props => props.bgArea}); */
    background-color: #eee;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    
    .preview {
        background-image: url(${props => props.bg});
        width: 640px;
        height: 360px;
        background-size: contain;
        position: relative;
        .topBox {
          height: 91px;
            h2 {
                position: absolute;
                text-transform: uppercase;
                color: #037ABE;
                margin: 0px;
                top: 15px;
                left: 30px;
                font-size: 53px;
            }            
        }
        .bottomBox {
            text-align: center;
            height: 198px;
            display: grid;
            justify-content: center;
            align-content: center;
            h1 {
                font-size: 40px;
                margin: 0px;
                margin-bottom: 10px;
            }
        }
        .companyBox {
            position: absolute;
            bottom: 20px;
            left: 305px;
            h3 {
                color: #fff;
                font-weight: 500;
            }
        }
    }

    
  }
  .leftSection {
    margin-top: 60px;
  }
  .rightSection {
      h4 {
        color: #9c0000;
      }
  }

  span {
    margin: 20px;
  }

  @media only screen and (max-width: 1025px) {
    input {
      width: 400px !important;
    }
    .rightSection  {
      .videoPlayer {
        width: 439px !important;
        height: 247px !important;
      }
    }
    .bottomSection .preview {
      width: 439px !important;
      height: 247px !important;
      .topBox {
        height: 62px;
        h2 {
          top: 11px;
          left: 24px;
          font-size: 36px;
        }
      }
      .bottomBox {
        height: 135px;
        h1 {
          font-size: 45px;
        }
      }
      .companyBox {
        bottom: 5px;
        left: 210px;
        h3 {

        }
      }
    }
  }

  @media only screen and (max-width: 500px) {

    background-image: none;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 11fr;
    .topSection {
      grid-template-columns: 1fr;
      height: auto;
      padding: 20px 30px;
      text-align: center;
    img {
      width: 200px;
      text-align: center;
      margin: auto;
    }
    h2 {
      display: none;
    }
    }
    .bottomSection {
      grid-template-columns: 1fr;
      height: auto;
      grid-template-rows: 6fr 9fr;
    }
    .rightSection  {
      .videoPlayer {
        width: 360px !important;
      }
    }
    .leftSection {
      order: 1;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 22px;
    }
    input {
      width: 360px !important;
    }
    .bottomSection .preview {
      width: 360px !important;
      height: 203px !important;
      .topBox {
        height: 62px;
        h2 {
          top: 11px;
          left: 24px;
          font-size: 25px;
        }
      }
      .bottomBox {
        height: 86px;
        h1 {
          font-size: 34px;
        }
      }
      .companyBox {
        bottom: 18px;
        left: 174px;
        h3 {
          font-size: 16px;
          margin: 0px;
        }
      }
    }
  }


`;

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: "",
      line1: "",
      line2: "",
      line3: "",
      nameCompany: "",
      message: "",
      video: false
    };
  }

  componentDidMount() {
    const ref = firebase
      .firestore()
      .collection("welcome")
      .doc("details");
    ref.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        this.setState({
          key: doc.id,
          line1: data.line1,
          line2: data.line2,
          line3: data.line3,
          nameCompany: data.nameCompany,
          message: data.message,
          video: data.video
        });
      } else {
        console.log("No such document!");
      }
    });
  }

  onChange = e => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState({ data: state });
  };

  onSubmit = e => {
    e.preventDefault();

    const { line1, line2, line3, nameCompany, message, video } = this.state;

    this.setState({
      key: "details",
      // line1: line1,
      // line2: line2,
      // line3: line3,
      // nameCompany: nameCompany,
      // message: message,
      video: false
    });

    const updateRef = firebase
      .firestore()
      .collection("welcome")
      .doc(this.state.key);
    updateRef
      .set({
        line1,
        line2,
        line3,
        nameCompany,
        message,
        video: false
      })
      .catch(error => {
        console.error("Error adding document: ", error);
      });
  };

  onChange = e => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState({ data: state });
  };

  onVideoSubmit = e => {
    e.preventDefault();
    console.log("Video Update", this.state);
    const { line1, line2, line3, nameCompany, message, video } = this.state;

    const updateRef = firebase
      .firestore()
      .collection("welcome")
      .doc(this.state.key);
    updateRef
      .set({
        line1,
        line2,
        line3,
        nameCompany,
        message,
        video: true
      })
      .then(docRef => {
        this.setState({
          key: "details",
          line1: line1,
          line2: line2,
          line3: line3,
          nameCompany: nameCompany,
          message: message,
          video: true
        });
        this.props.history.push("/settings");
        console.log(this.state);
      })
      .catch(error => {
        console.error("Error adding document: ", error);
      });
  };

  render() {
    return (
      <Container bg={BG} bgArea={bgArea}>
        <div className='topSection'>
          <img src={Logo} alt='logo' />
          <h2>Settings</h2>
        </div>
        <div className='bottomSection'>
          <div className='leftSection'>
            <div className='formBox'>
              <p>
                If the first name and last name fields are not filled, the
                company name will be the main title.
              </p>
              <form onSubmit={this.onSubmit}>
                <div className='form-group'>
                  <label for='message'>Message:</label>
                  <input
                    type='text'
                    className='form-control'
                    name='message'
                    value={this.state.message}
                    onChange={this.onChange}
                  />
                </div>
                <div className='form-group'>
                  <label for='line1'>Line 1:</label>
                  <input
                    type='text'
                    className='form-control'
                    name='line1'
                    value={this.state.line1}
                    onChange={this.onChange}
                  />
                </div>
                <div className='form-group'>
                  <label for='line2'>Line 2:</label>
                  <input
                    type='text'
                    className='form-control'
                    name='line2'
                    value={this.state.line2}
                    onChange={this.onChange}
                  />
                </div>
                <div className='form-group'>
                  <label for='line3'>Line 3:</label>
                  <input
                    type='text'
                    className='form-control'
                    name='line3'
                    value={this.state.line3}
                    onChange={this.onChange}
                  />
                </div>
                <div className='form-group'>
                  <label for='nameCompany'>Company Name:</label>
                  <input
                    type='text'
                    className='form-control'
                    name='nameCompany'
                    value={this.state.nameCompany}
                    onChange={this.onChange}
                  />
                </div>
                <div className='form-footer'>
                  <button type='submit' className='btn btn-success'>
                    Update Text
                  </button>
                  <span>OR</span>
                  <button onClick={this.onVideoSubmit}>Play Video</button>
                </div>
              </form>
            </div>
          </div>
          <div className='rightSection'>
            <h2>Live Preview</h2>
            {this.state.video && (
              <ReactPlayer
                className='videoPlayer'
                url={video}
                loop
                muted
                playing
                width='640px'
                height='360px'
              />
            )}
            {!this.state.video &&
              this.state.line1 &&
              this.state.line2 &&
              this.state.line3 && (
                <div className='preview' bg={BG}>
                  <div className='topBox'>
                    <h2>{this.state.message}</h2>
                  </div>
                  <div className='bottomBox'>
                    <h1>{this.state.line1}</h1>
                    <h1>{this.state.line2}</h1>
                    <h1>{this.state.line3}</h1>
                  </div>
                  <div className='companyBox'>
                    <h3>{this.state.nameCompany}</h3>
                  </div>
                </div>
              )}
            {!this.state.video &&
              this.state.line1 &&
              this.state.line2 &&
              !this.state.line3 && (
                <div className='preview' bg={BG}>
                  <div className='topBox'>
                    <h2>{this.state.message}</h2>
                  </div>
                  <div className='bottomBox'>
                    <h1>{this.state.line1}</h1>
                    <h1>{this.state.line2}</h1>
                  </div>
                  <div className='companyBox'>
                    <h3>{this.state.nameCompany}</h3>
                  </div>
                </div>
              )}
            {!this.state.video &&
              this.state.line1 &&
              !this.state.line2 &&
              !this.state.line3 && (
                <div className='preview' bg={BG}>
                  <div className='topBox'>
                    <h2>{this.state.message}</h2>
                  </div>
                  <div className='bottomBox'>
                    <h1>{this.state.line1}</h1>
                  </div>
                  <div className='companyBox'>
                    <h3>{this.state.nameCompany}</h3>
                  </div>
                </div>
              )}
            {!this.state.video &&
              !this.state.line1 &&
              !this.state.line2 &&
              !this.state.line3 && (
                <div className='preview' bg={BG}>
                  <div className='topBox'>
                    <h2>{this.state.message}</h2>
                  </div>
                  <div className='bottomBox'>
                    <h1>{this.state.nameCompany}</h1>
                  </div>
                  <div className='companyBox'></div>
                </div>
              )}
          </div>
        </div>
      </Container>
    );
  }
}
export default Settings;
